<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">专业管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addMajor()">创建专业</el-button>
            </div>
        </div>
        <el-table :data="majorListTable" class="college-table" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="专业名称" align="left"></el-table-column>
            <el-table-column label="操作" align="center" width="160">
                <template slot-scope="scope">
                    <el-button size="small" @click="editMajor(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteMajor(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="majorPages.currentPageNum"
                :page-size="majorPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="majorPages.total"
                @current-change="majorCurrentChange">
        </el-pagination>
        <!--添加/编辑弹窗-->
        <el-dialog :title="majorTitleType" :visible.sync="dialogAddMajor" center width="30%" @close="resetForm()" @opened="openAddMajor" :close-on-click-modal="false">
            <el-form :model="addMajorForm" ref="addMajorForm" :rules="rules" label-position="left">
                <el-form-item label="专业名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addMajorForm.name" ref="majorInput" autocomplete="off" placeholder="1-20字符" style="width: 80%;" ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddMajor = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addMajorForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "EducationManage",
        data(){
            return{
                majorListTable:[],
                //弹窗标题
                majorTitleType: '',
                //是否显示弹窗
                dialogAddMajor: false,
                formLabelWidth: '80px',
                //编辑/保存
                distinguishBtn: '',
                //分页
                majorPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
                addMajorForm: {
                    id: '',
                    name: ''
                },
                rules: {
                    name: [
                        { required: true, message: '请输入专业名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ]
                },
            }
        },
        created() {
            this.getMajorList();
        },
        methods:{
            // 获取专业列表
            getMajorList(){
                let param = {
                    page: this.majorPages.currentPageNum,
                    pageSize: this.majorPages.eachPageNum,
                    paging: 1,
                };
                this.$http.axiosGetBy(this.$api.getMajor,param,(res) => {
                    // console.log(res,'555')
                    if (res.code === 200) {
                        this.majorListTable = res.data.data;
                        this.majorPages.total = res.data.total;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 创建专业
            addMajor() {
                this.dialogAddMajor = !this.dialogAddMajor;
                this.majorTitleType = '创建专业';
                this.distinguishBtn = 'add';
            },
            //编辑专业
            editMajor(row) {
                this.dialogAddMajor = !this.dialogAddMajor;
                this.majorTitleType = '编辑专业';
                this.distinguishBtn = 'edit';
                this.addMajorForm.id = row.id;
                this.addMajorForm.name = row.name;
            },
            //保存专业
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$http.axiosGetBy(this.$api.getName, {name:this.addMajorForm.name}, res =>{
                            if(res.code === 200) {
                                let formData = new FormData();
                                formData.append("name",this.addMajorForm.name);
                                if (this.distinguishBtn === 'edit'){
                                    formData.append("id",this.addMajorForm.id);
                                }
                                this.$http.axiosPost(this.$api.getMajor,formData, res => {
                                    if (res.code === 200) {
                                        this.$message({
                                            type:"success",
                                            message:res.message,
                                            duration:1000,
                                            onClose:()=>{
                                                this.dialogAddMajor = !this.dialogAddMajor;
                                                this.resetForm();
                                                this.getMajorList();
                                            }
                                        });
                                    } else {
                                        this.$message({
                                            type:"error",
                                            message:res.message,
                                            duration:1000
                                        });
                                    }
                                }, err => {
                                    console.log(err);
                                });
                            }else {
                                this.$message({
                                    type:"error",
                                    message:res.message,
                                    duration:1000
                                });
                            }
                        });
                    }
                });
            },
            // 删除
            deleteMajor(row){
                this.$confirm('是否删除该专业，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.deleteMajor, {id: row.id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getMajorList(this.id);
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            //打开弹窗聚焦输入框
            openAddMajor() {
                this.$refs.majorInput.focus();
            },
            //重置表单
            resetForm() {
                this.$refs.addMajorForm.resetFields();
                this.addMajorForm = {
                    name: ''
                }
            },
            //分页
            majorCurrentChange(val) {
                this.majorPages.currentPageNum = val;
                this.getMajorList();
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 16px 9px;
    }
</style>